<template>
    <div>
      casa  {{ hotels }}
    </div>
</template>

<script setup>
import { useCollection, useFirestore } from 'vuefire'
import { collection } from 'firebase/firestore'

const db = useFirestore()

const hotels = useCollection(collection(db, 'hotels'));

console.log('hotels', hotels);
</script>